<template>
  <div>
    <b-row class="col-lg-2 mb-4">
      <label for="" class="font-weight-bold font-12">Records Per Page </label>
      <b-form-select class="hand" :options="pageOptions" v-model="perPage"/>
    </b-row>
    <b-table
            :fields="fields"
            :items="user_logs"
            bordered
            class="break"
            empty-text="No Record Found"
            fixed
            hover
            id="user_logs_table"
            responsive="true"
            show-empty
            small
            striped
    >
      <template v-slot:cell(raw_request)="cell">
        <span v-b-popover.hover.right :title="cell.item.raw_request">
          {{ trim_raw_request(cell.item.raw_request) }}
        </span>
      </template>
      <template v-slot:cell(raw_response)="cell">
        <span v-b-popover.hover.right :title="cell.item.raw_response">
          {{ trim_raw_request(cell.item.raw_response) }}
        </span>
      </template>
      <template v-slot:cell(browser)="cell">
        <span>{{ users_logs_info(cell, "browser") }}</span>
      </template>
      <template v-slot:cell(device)="cell">
        <span>{{ users_logs_info(cell, "device") }}</span>
      </template>
      <template v-slot:cell(platform)="cell">
        <span>{{ users_logs_info(cell, "platform") }}</span>
      </template>
      <template v-slot:cell(ip_address)="cell">
        <span>{{ users_logs_info(cell, "ip_address") }}</span>
      </template>
      <template v-slot:cell(user_agent)="cell">
        <span>{{ users_logs_info(cell, "user_agent") }}</span>
      </template>
    </b-table>
    <b-pagination align="right" aria-controls="user_logs_table" :total-rows="pagination.total"
                  :per-page="pagination.per_page" v-model="currentPage"/>
  </div>
</template>

<script>
import {mapState} from "vuex";
  export default {
    name: "UserLogs",
    data() {
      return {
        fields: [
          {
            key: "user_id",
            label: "user_id",
            thStyle: {
              width: "57px"
            }
          },
          {
            key: "raw_request",
            sortable: true,
            thStyle: {
              width: "200px"
            }
          },
          {
            key: "raw_response",
            sortable: true,
            thStyle: {
              width: "200px"
            }
          },
          {
            key: "ip_address",
            sortable: true,
            thStyle: {
              width: "78px"
            }
          },
          {
            key: "user_agent",
            thStyle: {
              width: "150px"
            }
          },
          {
            key: "device",
            thStyle: {
              width: "76px"
            }
          },
          {
            key: "browser",
            thStyle: {
              width: "67px"
            }
          },
          {
            key: "platform",
            thStyle: {
              width: "65px"
            }
          },
          {
            key: "is_phone",
            thStyle: {
              width: "53px"
            }
          },
          {
            key: "is_desktop",
            thStyle: {
              width: "63px"
            }
          },
          {
            key: "is_robot",
            thStyle: {
              width: "48px"
            }
          },
          {
            key: "created_at",
            thStyle: {
              width: "74px"
            }
          },
          {
            key: "updated_at",
            thStyle: {
              width: "80px"
            }
          }
        ],
        user_logs   : [],
        currentPage : 1,
        perPage     : 10,
        pageOptions : [10,25,50,100]
      };
    },
    computed:{
      ...mapState(['pagination']),
      pagination(){
        return this.$store.state.pagination
      }
    },
    mounted() {
      this.getUserLogs(this.$route.params.id);
    },
    watch:{
      currentPage(){
        this.getUserLogs(this.$route.params.id);
      },
      perPage(){
        this.getUserLogs(this.$route.params.id);
      }
    },
    methods: {
      getUserLogs(user_id) {
        let data = {
          per_page: this.perPage,
          page    : this.currentPage,
          user_id : user_id
        };
        this.$store.dispatch("getUsersLogs", data).then(response => {
          this.$store.commit("toggle_loader", false);
          this.$store.commit('set_pagination',response.data.pagination)
          this.user_logs = response.data.users_logs.data;
          this.$store.commit("set_busy", false);
        });
      },
      trim_raw_request(raw_request) {
        return raw_request.length > 40
                ? raw_request.slice(0, 40) + "..."
                : raw_request;
      },

      users_logs_info(cell1, cell2) {
        if (cell2 === "browser") {
          return cell1.item.browser.name;
        } else if (cell2 === "device") {
          return cell1.item.device.name;
        } else if (cell2 === "platform") {
          return cell1.item.platform.name;
        } else if (cell2 === "ip_address") {
          return cell1.item.ip_address.name;
        } else if (cell2 === "user_agent") {
          return cell1.item.user_agent.name;
        }
      }
    }
  };
</script>

<style scoped>
  .break {
    max-height: 80vh;
    overflow: scroll;
  }
</style>
<style>
  .popover-header {
    padding: 10px !important;
    margin-bottom: -16px !important;
    font-size: 12px !important;
    background-color: #f7f7f7;
    border-bottom: 1px solid #ebebeb;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
    width: 280% !important;
    heigt: 300vh !important;
  }
</style>
